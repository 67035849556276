import './App.css';
import React, { useRef, useEffect } from 'react';
import Murray from './Murray';
function App() {
  const interact = useRef();

  useEffect(() => {
    interact.current.addEventListener('click', () => handleEvent('click'))
    interact.current.addEventListener('touchstart', () => handleEvent('touchstart'))
  }, []);

  const handleEvent = (event) => {
    const videos = document.querySelectorAll(".video");
    videos.forEach((video, index) => {
      if (!isVideoPlaying(video)) {
        video.play()
        .catch(error => {
          console.log('error => ', error) 
        })
      }
    })
  }
  const isVideoPlaying = video => !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);

  return (
    <div className="App" ref={interact}>
    <Murray/> 
    </div>
  );
}

export default App;
